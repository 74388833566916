import React, { useEffect, useState } from "react";
import { DeleteButton, EditButton } from "../../../components/Actions";
import { convertToTime } from "../../../utilities/time";
import { convertToDate } from "../../../utilities/datetime";
import SwitchButton from "../../../components/Switch";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
  allPatientTableDataEndPoint,
  branchListEndPoint,
  leadAgentListEndPoint,
  patientEndPoint,
  patientTypeListEndPoint,
  sorceTypeListEndPoint,
  sourcedisplaytypedropdownEndPoint,
} from "../../../service/api";
import Searchbar from "../../../components/SearchBar";
import Pagination from "../../../components/Pagination";
import CustomePagination from "../../../components/CustomPagination";
import Popup from "../../../components/Popup";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker1 from "../../../components/DatePicker1";
import { useSelector } from "react-redux";
import CustomPopover from "../../../components/Popover";
import { Popover } from "@material-tailwind/react";
import { ExportToExcel } from "../../../components/ExportToExcel";

function Allpatient() {
  const navigate = useNavigate();
  const { action } = useSelector((state) => state.common);

  const {fromDate,toDate} = useParams()

  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [allpatientsTableList, setAllpatientsTableList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [formMode, setFormMode] = useState("create");
  const [currentPatient, setCurrentPatient] = useState({});
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [sourceDropdown, setSourceDropdown] = useState([]);
  const [patienttypeDropdown, setPatientTypeDropdown] = useState([]);
  const [sourcedisplayDropdown, setSourceDisplayDropdown] = useState([]);
  const [totalitems, setTotalitems] = useState({}); 
  const [agentList, setAgentList] = useState([]);
 const [allPatientExportList, setAllPatientExportList] = useState([]);


  const [value, setValue] = useState({
    startDate: fromDate,
    endDate: toDate,
  });

  const selectedBranchFromLocalStorage = localStorage.getItem('dasboardbranch');


  const [body, setBody] = useState({
    search: "",
    from_date:fromDate || "",
    to_date:toDate || "",
    page: 1,
    branch: selectedBranchFromLocalStorage || localStorage.getItem("branch")  ,
    items_per_page: 10,
    source_types:  localStorage.getItem("source_id") || "",
    patienttype:localStorage.getItem("entry_type") || "" ,
    source_displaytype: localStorage.getItem("source_displaytypes_id") || "",

  });

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  useEffect(() => {
    getAllPatientListData();
    getBranchDropdownData();
    getSourceDropdownData();
  patientTypeDropdownList();
  getSourceDisplayTypeDropdownData();
    
  }, [body]);

  useEffect(()=>{
    getAgentListData();
  },[])


  const getAgentListData = async () => {
    var response = await getAxios({
      url: leadAgentListEndPoint
    })

    if (response != null) {
      setAgentList(response.data.list)
    }
  }

  const getAllPatientListData = async () => {
    var response = await postAxiosWithToken({
      url: allPatientTableDataEndPoint,
      body: body,
    });

    if (response !== null) {
      setAllPatientExportList(response.data.list);
      setAllpatientsTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setTotalitems(response.data);
      setIsLoading(false);
    }
  };

  // const statusHandler = (e) => {
  //   setBody((prevState) => ({
  //     ...prevState,
  //     active_status: e.target.value === "" ? "" : JSON.parse(e.target.value),
  //     page: 1,
  //   }));
  // };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

const entriesHandler = (value) => {
  setBody((prevState) => ({
    ...prevState,
    items_per_page: value,
    page: 1,
  }));
};





  const handleDateFilter = async (e) => {
 
    if (e.startDate === null && e.endDate === null ){
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
        page: 1,
      }));
    }else{
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
        page: 1,
      }));
    }
    
    setValue(e)
  };

  const deletepatient = async () => {
    var response = await deleteAxiosWithToken({
      url: patientEndPoint + currentPatient.id + "/",
    });

    if (response !== null) {
      getAllPatientListData();
      closeDeleteModal();
      toast.success(response.message);
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const getSourceDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeListEndPoint,
    });

    if (response !== null) {
      setSourceDropdown(response.data.list);
    }
  };
  const getSourceDisplayTypeDropdownData = async () => {
    var response = await getAxios({
      url: sourcedisplaytypedropdownEndPoint,
    });

    if (response !== null) {
      setSourceDisplayDropdown(response.data.list);
    }
  };
 
  const patientTypeDropdownList = async () => {
    var response = await getAxios({
      url: patientTypeListEndPoint ,
    });

    if (response !== null) {
      setPatientTypeDropdown(response.data.list);
    }
  };





  return (
    <div>
      <div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[650px]:block max-[650px]:mb-[50px] ">
        <p className=" sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
          Patient
        </p>

        <div className="flex gap-[10px] max-[520px]:mt-5 max-[575px]:block ">

        <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
                <select
                  placeholder="Patient Type"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
                  name="patienttypes"
                  value={body.patienttype}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      patienttype: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select Patient Type--</option>
                  {patienttypeDropdown?.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.typename}
                    </option>
                  ))}
                </select>
             
            </div>



        <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
                <select
                  placeholder="Source"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
                  name="source_types"
                  value={body.source_types}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      source_types: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select Source--</option>
                  {sourceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select>
             
            </div>








          

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>

          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>

         

         
        </div>
      </div>

      <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
      <ExportToExcel apiData={allPatientExportList} fileName="Patient" />
      <div className="h-[40px] w-[200px] max-[425px]:mt-[5px] max-[425px]:w-full ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>

      <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
                <select
                  placeholder="Source display"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
                  name="source_displaytype"
                  value={body.source_displaytype}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      source_displaytype: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select Display type--</option>
                  {sourcedisplayDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_displayname}
                    </option>
                  ))}
                </select>
             
            </div>







        


        <div className="max-[575px]:w-full max-[575px]:mt-4">
            {/* {localStorage.getItem("branch") != 0 ? ( */}
              <button
                className="flex bg-[#0176FF] text-white p-[8px] rounded-md"
               
                onClick={() => {
                  if (action.addPermit) {
                    localStorage.setItem("patient_form_mode", "create");
                    navigate("/all-patient/create-patient");
                  } else{
                    toast.error("You don't have permission for create")
                  }
                  
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16 19H22M19 16V22M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z"
                    stroke="white"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Add New Patient
              </button>
             {/* ) : (
               ""
             )} */}
          </div>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
            <td className="p-[10px]">S.No</td>
            {localStorage.getItem('calllog') == 1 ?
              <>
              <td className="p-[10px] min-w-[100px]">Call Action</td>
              <td className="p-[10px] min-w-[150px]">Call Count</td></>:""}
              <td className="p-[10px] min-w-[200px]">File No</td>
              <td className="p-[10px] min-w-[200px]">Name</td>
              <td className="p-[10px] min-w-[200px]">Spouse Name</td>
              <td className="p-[10px] min-w-[200px]">Mobile No</td>
              <td className="p-[10px] min-w-[200px]">Source Type</td>
              <td className="p-[10px] min-w-[200px]">Lead Type</td>
              <td className="p-[10px] min-w-[200px]">Patient Type</td>
              <td className="p-[10px] min-w-[200px]">Branch</td>
              <td className="p-[10px] min-w-[200px]">Create Date</td>
              <td className="p-[10px] min-w-[200px]">Last Update Date</td>
              <td className="p-[10px] min-w-[200px]">Created by</td>
              {/* {localStorage.getItem("branch") == 0 ? (""):( */}
              <td className="p-[10px] sticky right-0 bg-secondary">Action</td>
              {/* )} */}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={7}>
                  Loading...
                </td>
              </tr>
            ) : allpatientsTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={7}>
                  No Data
                </td>
              </tr>
            ) : (
              allpatientsTableList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{item.sno}</td>
                  {localStorage.getItem('calllog') == 1 ?
                  <>
                  
                  <td className="p-[10px] capitalize">
                  <div className='w-[150px]'>
                    <Popover
                      placement="bottom-end"
                      animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0, y: 25 },
                      }}
                    ></Popover>
                    <CustomPopover options={agentList} customerMobile={item.phone} leadId={item.id} />
                  </div>
                </td>
                <td className="p-[10px]">{item.call_count}</td>
                </>
                  :""}
                  <td className="p-[10px] capitalize">{ item.file_no != null ? item.file_no :"----"}</td>
                  <td className="p-[10px] capitalize">{item.name}</td>
                  <td className="p-[10px] capitalize">{item.spouse_name}</td>
                  <td className="p-[10px] capitalize">{item.phone}</td>
                  <td className="p-[10px] capitalize">{item.source_type}</td>
                  <td className="p-[10px] capitalize">{item.lead_type}</td>
                  <td className="p-[10px] capitalize">{item.patienttype}</td>
                  <td className="p-[10px] capitalize">{item.branch_name}</td>
                  
                  
                  <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        { item.created_at != "0000-00-00 00:00" ? convertToDate(item.created_at) :"0000-00-00"}
                      </p>
                      <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      { item.created_at != "0000-00-00 00:00" ? convertToTime(item.created_at) :"0000-00-00"}
                      </p>
                    </td>
                    <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                      { item.modified_at != "0000-00-00 00:00" ? convertToDate(item.modified_at) :"0000-00-00"}
                      
                      </p>
                      <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      { item.modified_at != "0000-00-00 00:00" ? convertToTime(item.modified_at) :"0000-00-00"}
                      </p>
                    </td>

                    <td className="p-[10px] capitalize">{item.created_by_name}</td>

 

                  

                  <td className="p-[10px]  sticky right-0 bg-white">
                    <div className="flex gap-3">
                      {/* {localStorage.getItem("branch") != 0 ? ( */}
                        <div
                         onClick={() => {
                            if (action.editPermit) {
                              setCurrentPatient(item);
                            localStorage.setItem("patient_form_mode", "update");
                            localStorage.setItem(
                              "patient_form_id",
                              item.id.toString()
                            );
                            navigate("/all-patient/create-patient");
                            } else{
                              toast.error("You don't have permission for update")
                            }
                            
                          }}
                          className="cursor-pointer hover:scale-125"
                        >
                          <EditButton />
                        </div>
                       {/* ) : (
                         ""
                       )} */}
                      <div
                      

                        onClick={() => {
                          if (action.deletePermit) {
                            setCurrentPatient(item);
                          setIsDeleteOpen(true);
                          } else {
                            toast.error("You don't have permission for delete");
                          }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <DeleteButton />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>


      <div className="mb-[50px]">
      <CustomePagination
  totalPages={totalPages}
  currentPage={body.page}
  setCurrentPage={pageHandler}
  entries={body.items_per_page}
  entriesHandler={entriesHandler}
  totalitmes={totalitems.total_items}
/>
      </div>



      
      <div>
        <Popup
          title={"Delete patient"}
          setIsOpen={setIsDeleteOpen}
          isOpen={isDeleteOpen}
          closeModal={closeDeleteModal}
        >
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">
              Do you want to delete the {currentPatient?.patient_name}{" "}
              patient?
            </p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button
                onClick={() => closeDeleteModal()}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={() => {
                  deletepatient();
                }}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default Allpatient;
