import React, { useEffect, useState } from 'react'
import { DeleteButton, EditButton, ViewoutpatientButton } from '../../../components/Actions';
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from '../../../service/apiservice';
import { allprocedureEndpoint, branchListEndPoint, entryDropdownprocedureListEndPoint, leadAgentListEndPoint, procedureCreateEndpoint, procedureDropdownListEndPoint, sorceTypeListEndPoint, typewayListEndPoint } from '../../../service/api';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToTime } from "../../../utilities/time";
import { convertToDate } from "../../../utilities/datetime";
import CustomePagination from '../../../components/CustomPagination';
import Searchbar from '../../../components/SearchBar';
import DatePicker1 from '../../../components/DatePicker1';
import toast from 'react-hot-toast';
import Popup from '../../../components/Popup';
import Viewdischargedetails from './Viewdetails';
import { useSelector } from 'react-redux';
import CustomPopover from '../../../components/Popover';
import { Popover } from '@material-tailwind/react';

import { ExportToExcel } from "../../../components/ExportToExcel";
export default function Allprocedure() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [allprocedureTableList, setallprocedureTableList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentProcedure, setCurrentProcedure] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isviewOpen, setIsviewOpen] = useState(false);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [procedureTypeDropdown, setProcedureTypeDropdown] = useState([]);
  const [typewayDropdown, setTypewayDropdown] = useState([]);
  const [entryTypeDropdown, setentryTypeDropdown] = useState([]);
  const [totalitems, setTotalitems] = useState({});
  const [agentList, setAgentList] = useState([]);
  const [sourceDropdown, setSourceDropdown] = useState([]);
  const { action } = useSelector((state) => state.common);
  const [allProcedureExportList, setAllProcedureExportList] = useState([]);
 

  const {fromDate,toDate} = useParams()
  const selectedBranchFromLocalStorage = localStorage.getItem('dasboardbranch');
  const [value, setValue] = useState({
    startDate: fromDate,
    endDate: toDate,
  });
  const [body, setBody] = useState({
    search: "",
    entry_type: "",
    source_types:"",
    branch: selectedBranchFromLocalStorage || localStorage.getItem("branch"),
    from_date: fromDate || "",
    to_date: toDate || "",
    page: 1,
    items_per_page: 10,
    procedure_type: "",
    typeway: ""
  });



  useEffect(() => {
    getAllprocedureListData();
    getBranchDropdownData();
    ProcedureTypeDropdownList();
    TypewayDropdownList();
    EntryDropdownList();
  }, [body]);

  useEffect(() => {
    setBody((prevState) =>({
      ...prevState,
      typeway: localStorage.getItem("admission") || localStorage.getItem("type_way") || ""
    }))
    localStorage.removeItem("admission")
    localStorage.removeItem("type_way")
    getAgentListData();
    getSourceDropdownData();
  },[])


    const getSourceDropdownData = async () => {
      var response = await getAxios({
        url: sorceTypeListEndPoint,
      });
  
      if (response !== null) {
        setSourceDropdown(response.data.list);
      }
    };

  const getAgentListData = async () => {
    var response = await getAxios({
      url: leadAgentListEndPoint
    })

    if (response != null) {
      setAgentList(response.data.list)
    }
  }




  const ProcedureTypeDropdownList = async () => {
    var response = await getAxios({
      url: procedureDropdownListEndPoint,
    });

    if (response !== null) {
      setProcedureTypeDropdown(response.data.list)
    }
  }


  const TypewayDropdownList = async () => {
    var response = await getAxios({
      url: typewayListEndPoint,
    });

    if (response !== null) {
      setTypewayDropdown(response.data.list)
    }
  }


  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }


  function closeviewModal() {
    setIsviewOpen(false);
  }

  const getAllprocedureListData = async () => {
    var response = await postAxiosWithToken({
      url: allprocedureEndpoint,
      body: body,
    });

    if (response !== null) {
      setTotalPages(response.data.total_pages);
      setAllProcedureExportList(response.data.list);
      setallprocedureTableList(response.data.list);
      setTotalitems(response.data);
      setIsLoading(false);
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const deletePatientGroup = async () => {
    var response = await deleteAxiosWithToken({
      url: procedureCreateEndpoint + currentProcedure.id + "/",
    });

    if (response !== null) {
      if (response.status === 200) {
        getAllprocedureListData();
        closeDeleteModal();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };


  //Filters-start


  const EntryDropdownList = async () => {
    var response = await getAxios({
      url: entryDropdownprocedureListEndPoint,
    });

    if (response !== null) {
      setentryTypeDropdown(response.data.list)
    }
  }

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const handleDateFilter = async (e) => {
    if (e.startDate === null && e.endDate === null) {
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
        page: 1,
      }));
    } else {
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
        page: 1,
      }));
    }
    setValue(e);
  };




  return (
    <div>





      <div className="flex justify-between min-[1551px]:flex gap-[20px] items-center h-[40px] mb-[24px] mt-[24px] max-[1550px]:block max-[650px]:mb-[50px]">
        <div>
          <p className='text-zinc-900 text-2xl font-medium leading-7'>All Procedure</p>
        </div>

        <div className="flex justify-end gap-[10px] max-[520px]:block max-[520px]:mt-5 mt-5">
        

        <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
                <select
                  placeholder="Source"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
                  name="source_types"
                  value={body.source_types}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      source_types: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select Source--</option>
                  {sourceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select>
             
            </div>

          {localStorage.getItem("branch") == 0 ? (
            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              <select
                placeholder="Branch"
                className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                name="branch"
                value={body.branch}
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    branch: e.target.value,
                    page: 1,
                  }));

                }}
              >
                <option value={""}>--select branch--</option>
                {branchDropdown.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.branch_name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}

          <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
            <select
              placeholder="Branch"
              className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
              name="branch"
              value={body.typeway}
              onChange={(e) => {
                setBody((prevState) => ({
                  ...prevState,
                  typeway: e.target.value,
                  page: 1,
                }));

              }}
            >
              <option value={""}>--select typeway--</option>
              {typewayDropdown.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className="capitalize"
                >
                  {option.proceduretypeway_name}
                </option>
              ))}
            </select>
          </div>

          <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
            <select
              placeholder="Procedure"
              className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
              name="procedure"
              onChange={(e) => {
                setBody((prevState) => ({
                  ...prevState,
                  procedure_type: e.target.value,
                  page: 1,
                }));

              }}
            >
              <option value={""}>--select procedure--</option>
              {procedureTypeDropdown.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className="capitalize"
                >
                  {option.procedure_name}
                </option>
              ))}
            </select>
          </div>

          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>

        </div>
      </div>

      <div className='flex gap-[10px] justify-end mt-[80px]'>

        <ExportToExcel apiData={allProcedureExportList} fileName="Procedure" />
        <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
          <select
            placeholder="Entry type"
            className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
            name="entry type"
            onChange={(e) => {
              setBody((prevState) => ({
                ...prevState,
                entry_type: e.target.value,
                page: 1,
              }));

            }}
          >
            <option value={""}>--select entry type--</option>
            {entryTypeDropdown.map((option) => (
              <option
                key={option.id}
                value={option.id}
                className="capitalize"
              >
                {option.entry_name}
              </option>
            ))}
          </select>
        </div>

        <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>



        {/* {localStorage.getItem("branch") == 0 ? (
          ""
        ) : ( */}
          <button className="flex bg-[#0176FF] text-white p-[10px] rounded-md" 
          onClick={() => {
            if (action.addPermit) {
              localStorage.setItem('procedure_form_mode', 'create'); navigate('/all-procedure/create-procedure')
            } else{
              toast.error("You don't have permission for create")
            }
            
          }}
          
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16 19H22M19 16V22M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z"
                stroke="white"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Add Procedure
          </button>
        {/* // )} */}
      </div>


      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg mt-[30px]">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px]">S.No</td>
              {localStorage.getItem('calllog') == 1 ?
              <>
              <td className="p-[10px] min-w-[100px]">Call Action</td>
              <td className="p-[10px] min-w-[150px]">Call Count</td></>:""}
              <td className="p-[10px] min-w-[200px]">File No</td>
              <td className="p-[10px] min-w-[200px]">Name</td>
              <td className="p-[10px] min-w-[200px]">Spouse Name</td>
              <td className="p-[10px] min-w-[200px]">Mobile No</td>
              <td className="p-[10px] min-w-[200px]">Procedure</td>
              <td className="p-[10px] min-w-[200px]">In Date</td>
              <td className="p-[10px] min-w-[200px]">Out Date</td>
              <td className="p-[10px] min-w-[200px]">Entry Type</td>
              <td className="p-[10px] min-w-[200px]">Source Type</td>
              <td className="p-[10px] min-w-[200px]">Lead Type</td>
              <td className="p-[10px] min-w-[200px]">Created by</td>
              <td className="p-[10px] min-w-[200px]">Procedure Date</td>
              <td className="p-[10px] min-w-[200px]">Create Date</td>
              <td className="p-[10px] min-w-[200px]">Last Update Date</td>

              <td className="p-[10px] min-w-[200px]">IP NO</td>
              {/* {localStorage.getItem("branch") == 0 ? ("") : ( */}
                <td className="p-[10px] sticky right-0 bg-secondary">Action</td>
                {/* )} */}
            </tr>
          </thead>
          <tbody>



            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={8}>
                  Loading...
                </td>
              </tr>
            ) : allprocedureTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={8}>
                  No Data
                </td>
              </tr>
            ) : (
              allprocedureTableList.map((item, index) => (
                <tr
                  key={item.id}
                  className="border">
                  <td className="p-[10px]">{item.sno}</td>
                  {localStorage.getItem('calllog') == 1 ?
                  <>
                  
                  <td className="p-[10px] capitalize">
                  <div className='w-[150px]'>
                    <Popover
                      placement="bottom-end"
                      animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0, y: 25 },
                      }}
                    ></Popover>
                    <CustomPopover options={agentList} customerMobile={item.mobile} leadId={item.id} />
                  </div>
                </td>
                <td className="p-[10px]">{item.call_count}</td>
                </>
                  :""}
                  <td className="p-[10px] capitalize">{ item.file_no != null ? item.file_no :"----"}</td>
                  <td className="p-[10px] capitalize">{item.patient_name}</td>
                  <td className="p-[10px] capitalize">{item.spouse_name}</td>
                  <td className="p-[10px]">
                    {item.mobile}
                  </td>
                  <td className="p-[10px]">
                    {item.procedure_name}
                  </td>
                  
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.in_date)}
                    </p>
                  </td>

                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {item.out_date ? convertToDate(item.out_date) : '0000-00-00'}
                    </p>
                  </td>
                  <td className="p-[10px] capitalize">
                    {item.entry_typename}
                  </td>
                  <td className="p-[10px]">
                    {item.source_types}
                  </td>
                  <td className="p-[10px]">
                    {item.lead_type}
                  </td>
                  <td className="p-[10px]">
                    {item.created_by}
                  </td>
                  <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {/* {convertToDate(item.procedure_date)} */}
                        {item.procedure_date ? convertToDate(item.procedure_date) : '00-00-0000'}

                      </p>
                    
                    </td>
                    <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {convertToDate(item.created_at ? item.created_at : '--')}
                      
                      </p>
                      <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                        {convertToTime(item.created_at ? item.created_at : '--')}
                      </p>
                    </td>
                    <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {convertToDate(item.modified_at ? item.modified_at : '--')}
                      
                      </p>
                      <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                        {convertToTime(item.modified_at ? item.modified_at : '--')}
                      </p>
                    </td>

                  <td className="p-[10px]">
                    {item.ip_no ? item.ip_no : '---'}
                  </td>
                  {/* {localStorage.getItem("branch") == "0" ? ("") : ( */}
                    <td className="p-[10px] sticky right-0 bg-white">
                      <div className="flex gap-3">

                        <div
                          onClick={() => {
                            localStorage.setItem("dischargeview_form_mode", "update");
                            localStorage.setItem("dischargeview_form_id", item.id.toString());
                            setIsviewOpen(true);
                          }}
                          className="cursor-pointer hover:scale-125">
                          <ViewoutpatientButton />
                        </div>

                        <div

                          onClick={() => {
                            if (action.editPermit) {
                              setCurrentProcedure(item);
                              localStorage.setItem("procedure_form_mode", "update");
                              localStorage.setItem("procedure_form_id", item.id.toString()
                              );
                              navigate("/all-procedure/create-procedure");
                            } else{
                              toast.error("You don't have permission for update")
                            }
                            
                          }}
                          className="cursor-pointer hover:scale-125"
                        >
                          <EditButton />

                        </div>
                        <div
                          onClick={() => {
                            if (action.deletePermit) {
                              setCurrentProcedure(item);
                              setIsDeleteOpen(true);
                            }
                            else {
                              toast.error("You don't have permission for delete")
                          }
                          }}
                          className="cursor-pointer hover:scale-125"
                        >
                          <DeleteButton />

                        </div>
                      </div>
                    </td>
                  {/* // )} */}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}
        />
      </div>


      <Popup
        title={"Delete All Procedure"}
        setIsOpen={setIsDeleteOpen}
        isOpen={isDeleteOpen}
        closeModal={closeDeleteModal}
      >
        <div className="mt-[10px]">
          <p className="font-400 text-[15px]">
            Do you want to delete the {currentProcedure?.procedure_name}{" "}
            All Procedure?
          </p>
          <div className="mt-[10px] flex gap-3 justify-end items-center">
            <button
              onClick={() => closeDeleteModal()}
              className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary"
            >
              Close
            </button>
            <button
              type="submit"
              onClick={() => {
                deletePatientGroup();
              }}
              className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        title={"patient Details"}
        setIsOpen={setIsviewOpen}
        isOpen={isviewOpen}
        closeModal={closeviewModal}
      >
        <Viewdischargedetails closeModal={closeviewModal} />
      </Popup>
    </div >
  )
}
