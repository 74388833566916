import React, { useEffect, useState } from "react";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
  LeadTypeDataEndPoint,
  allLeadsDeleteEndPoint,
  allLeadsTableDataEndPoint,
  branchListEndPoint,
  importBranchListEndPoint,
  leadAgentListEndPoint,
  sorceTypeListEndPoint,
  statusTypeListEndPoint,
} from "../../../service/api";
import { convertToDate } from "../../../utilities/datetime";
import { convertToTime } from "../../../utilities/time";
import CustomePagination from "../../../components/CustomPagination";
import Modal from "../../../components/Model";
import LeadDropDown from "../../../components/LeadsTableDropDown";
import { useNavigate, useParams } from "react-router-dom";
import Searchbar from "../../../components/SearchBar";
import DatePicker1 from "../../../components/DatePicker1";
import Popup from "../../../components/Popup";
import Addincomingform from "../incomingappointment/addincomingform";
import CustomPopover from "../../../components/Popover";
import { Popover } from "@mui/material";
import { DeleteButton, ViewButton } from "../../../components/Actions";
import ViewallLeads from "./ViewallLeads";
import Model from "../../../components/Model";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { ExportToExcel } from "../../../components/ExportToExcel";
import Singleleadform from "./singleleadform";

const AllLeads = () => {
  const [allleadsTableList, setAllleadsTableList] = useState([]);
  // const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentstatus, setCurrentstatus] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [statusTypeDropdown, setStatusTypeDropdown] = useState([]);
  const [LeadsDropdown, setLeadsDropdown] = useState([]);
  const [currentLeades, setCurrentLeades] = useState({});

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isIncommingOpen, setIsIncommingOpen] = useState(false);
  const [isviewOpen, setIsviewOpen] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const { fromDate, toDate, status_id } = useParams();
  const selectedBranchFromLocalStorage = localStorage.getItem("dasboardbranch");
  const [sourceDropdown, setSourceDropdown] = useState([]);
  const [totalitems, setTotalitems] = useState({});
  const [isIncommingOpen1, setIsIncommingOpen1] = useState(false);
  const [Addlead, setAddlead] = useState(false);




  const { action } = useSelector((state) => state.common);

  const [value, setValue] = useState({
    startDate: fromDate,
    endDate: toDate,
  });
  const [body, setBody] = useState({
    status: localStorage.getItem("status_id") || "",
    from_date: fromDate || "",
    to_date: toDate || "",
    branch: selectedBranchFromLocalStorage || localStorage.getItem("branch"),
    search: "",
    page: 1,
    items_per_page: 10,
    lead_type: "",
    source_type: "",
  });

  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  function closeViewIncommingModal() {
    setIsviewOpen(false);
  }

  function closeIncommingModal1() {
    setIsIncommingOpen1(false);
  }
  function closeAddlead() {
    setAddlead(false);
  }

  function closeIncommingModal() {
    setIsIncommingOpen(false);
  }

  const navigate = useNavigate();

  useEffect(() => {
    getAllLeadsListData();
  }, [body]);

  const getAllLeadsListData = async () => {
    var response = await postAxiosWithToken({
      url: allLeadsTableDataEndPoint,
      body: body,
    });

    if (response !== null) {
      setAllleadsTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setTotalitems(response.data);
      setIsLoading(false);
      
    }
  };
  useEffect(() => {
    getBranchDropdownData();
    getStatusDropdownData();
    getLeadsDropdownData();
    getAgentListData();
    getSourceDropdownData();
  }, []);

  const getAgentListData = async () => {
    var response = await getAxios({
      url: leadAgentListEndPoint,
    });

    if (response != null) {
      setAgentList(response.data.list);
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: importBranchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const getSourceDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeListEndPoint,
    });

    if (response !== null) {
      setSourceDropdown(response.data.list);
    }
  };

  const getLeadsDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadsDropdown(response.data.list);
    }
  };

  const getStatusDropdownData = async () => {
    var response = await getAxios({
      url: statusTypeListEndPoint,
    });

    if (response !== null) {
      setStatusTypeDropdown(response.data.list);
    }
  };

  // Filter
  const handleDateFilter = async (e) => {
    if (e.startDate === null && e.endDate === null) {
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
        page: 1,
      }));
    } else {
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
        page: 1,
      }));
    }

    setValue(e);
  };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  const deletepatient = async () => {
    var response = await deleteAxiosWithToken({
      url: allLeadsDeleteEndPoint + currentLeades.id + "/",
    });

    if (response !== null) {
      getAllLeadsListData();
      closeDeleteModal();
      toast.success(response.message);
    }
  };



  return (
    <div>
      <div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <div>
          <select
            onChange={(e) => {
              setBody((prevState) => ({
                ...prevState,
                status: e.target.value,
                page: 1,
              }));
            }}
            placeholder="Leads"
            value={body.status}
            className=" sm:mb-[5px]  bg-white border border-gray-300 rounded-lg w-full p-[5px]  text-[#1D1D1F] text-[18px] font-[500px] outline-none"
          >
            <option value={""}>All Leads</option>
            {statusTypeDropdown.map((items) => (
              <option
                value={items.id}
                id={items.id}
                className="text-[16px] text-[#1D1D1F] font-[400] "
              >
                {items.status}
              </option>
            ))}
          </select>
        </div>

        <div className="flex gap-[10px] max-[520px]:block max-[520px]:mt-5">
          <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
            <select
              placeholder="Source"
              className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
              name="source_type"
              onChange={(e) => {
                setBody((prevState) => ({
                  ...prevState,
                  source_type: e.target.value,
                  page: 1,
                }));
              }}
            >
              <option value={""}>--select Source--</option>
              {sourceDropdown.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className="capitalize"
                >
                  {option.source_name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex gap-[10px] max-[520px]:mt-5 ">
            <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
              <select
                placeholder="Leads Type"
                className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                name="lead_type"
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    lead_type: e.target.value,
                    page: 1,
                  }));
                }}
              >
                <option value={""}>--select LeadsType--</option>
                {LeadsDropdown.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.leadtype_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex gap-[10px] max-[520px]:mt-5 ">
            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>
        </div>
      </div>
      <div className="flex gap-[10px] justify-end">
        <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>

        <ExportToExcel apiData={allleadsTableList} fileName="Leads" />
        
        {localStorage.getItem("branch") == 0 ? 
        <button
          onClick={() => {
            if (action.addPermit) {
            localStorage.setItem("single_form_mode", "create");
            // localStorage.setItem("allleades_branch", "alllead");
            setAddlead(true);
            } else {  
              toast.error("You don't have permission for create");
            }
          }}
          className="w-[200px] capitalize bg-primary text-white rounded-lg p-[8px] h-fit font-[500] text-[17px] outline-none border-none"
        >
          <span className="flex justify-center items-center gap-[5px] text-[15px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16 19H22M19 16V22M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z"
                stroke="white"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Add Lead
          </span>
        </button> :""}

        <button
          onClick={() => {
            if (action.addPermit) {
              localStorage.setItem("incomming_form_mode", "create");
              localStorage.setItem("allleades_branch", "alllead");
              setIsIncommingOpen1(true);
            } else {
              toast.error("You don't have permission for create");
            }
          }}
          className="w-[200px] capitalize bg-primary text-white rounded-lg p-[8px] h-fit font-[500] text-[17px] outline-none border-none"
        >
          <span className="flex justify-center items-center gap-[5px] text-[15px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16 19H22M19 16V22M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z"
                stroke="white"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Create Appointment
          </span>
        </button>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px] min-w-[100px]">S.No</td>
              {localStorage.getItem("calllog") == 1 ? (
                <>
                  <td className="p-[10px] min-w-[100px]">Call Action</td>
                  <td className="p-[10px] min-w-[150px]">Call Count</td>
                </>
              ) : (
                ""
              )}
              <td className="p-[10px] min-w-[200px]">Name</td>
              <td className="p-[10px] min-w-[200px]">Mobile No</td>
              <td className="p-[10px] min-w-[200px]">Status</td>
              <td className="p-[10px] min-w-[100px]">Remarks</td>
              <td className="p-[10px] min-w-[200px]">Source Type</td>
              <td className="p-[10px] min-w-[200px]">Appointment Date</td>
              <td className="p-[10px] min-w-[200px]">Created Date</td>
              <td className="p-[10px] min-w-[200px]">Last Update Date</td>
              <td className="p-[10px] min-w-[200px]">Branch</td>
              <td className="p-[10px] min-w-[200px]">Lead Type</td>
              <td className="p-[10px] min-w-[200px]">Location</td>
              <td className="p-[10px] min-w-[200px]">Camp/Daily Walkin</td>
              <td className="p-[10px] min-w-[100px]">Created By</td>
              <td className="p-[10px] sticky right-0 bg-secondary">Action</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={6}>
                  Loading...
                </td>
              </tr>
            ) : allleadsTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={6}>
                  No Data
                </td>
              </tr>
            ) : (
              allleadsTableList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{item.sno}</td>

                  {localStorage.getItem("calllog") == 1 ? (
                    <>
                      <td className="p-[10px] capitalize">
                        <div className="w-[150px]">
                          <Popover
                            placement="bottom-end"
                            animate={{
                              mount: { scale: 1, y: 0 },
                              unmount: { scale: 0, y: 25 },
                            }}
                          ></Popover>
                          <CustomPopover
                            options={agentList}
                            customerMobile={item.mobile}
                            leadId={item.id}
                          />
                        </div>
                      </td>

                      <td className="p-[10px]">{item.call_count}</td>
                    </>
                  ) : (
                    ""
                  )}

                  <td className="p-[10px]">{item.name}</td>

                  <td className="p-[10px]">{item.mobile}</td>
                  <td className="p-[10px] capitalize">
                    <div className="relative">
                      <div
                        className="text-white px-[5px] py-[10px] rounded-[10px] w-[150px]"
                        style={{ backgroundColor: item.status_color }}
                      >
                        {item.status === 8 ? (
                          <div className="flex gap-[10px] justify-center items-center ">
                            {item.status_name}
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setCurrentstatus(item);
                              setIsStatusOpen(true);
                            }}
                            className="flex gap-[10px] justify-center items-center cursor-pointer"
                          >
                            {item.status_name}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                            >
                              <g
                                fill="none"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              >
                                <path d="M8 13V4.5a1.5 1.5 0 0 1 3 0V12m0-.5v-2a1.5 1.5 0 0 1 3 0V12m0-1.5a1.5 1.5 0 0 1 3 0V12" />
                                <path d="M17 11.5a1.5 1.5 0 0 1 3 0V16a6 6 0 0 1-6 6h-2h.208a6 6 0 0 1-5.012-2.7L7 19c-.312-.479-1.407-2.388-3.286-5.728a1.5 1.5 0 0 1 .536-2.022a1.867 1.867 0 0 1 2.28.28L8 13M5 3L4 2m0 5H3m11-4l1-1m0 4h1" />
                              </g>
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>

                  <td className="p-[10px] capitalize">
                    {item.remarks ? item.remarks : "---"}
                  </td>
                  <td className="p-[10px] capitalize">{item.source_name}</td>
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {item.appointment_date
                        ? convertToDate(item.appointment_date)
                        : "00-00-0000"}
                    </p>
                  </td>
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.created_at ? item.created_at : "--")}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(item.created_at ? item.created_at : "--")}
                    </p>
                  </td>

                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {item.modified_at != "0000-00-00 00:00"
                        ? convertToDate(item.modified_at)
                        : "0000-00-00"}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {/* {convertToTime(item.modified_at ? item.modified_at : '--')} */}
                      {item.modified_at != "0000-00-00 00:00"
                        ? convertToTime(item.modified_at)
                        : "0000-00-00"}
                    </p>
                  </td>
                  <td className="p-[10px] capitalize">{item.branch_name}</td>
                  <td className="p-[10px] capitalize">{item.leadtype_name}</td>

                  <td className="p-[10px] capitalize">{item.location}</td>
                  <td className="p-[10px] capitalize">
                    {item.camp_name ? item.camp_name : "---"}
                  </td>

                  <td className="p-[10px] capitalize">
                    {item.created_by_name}
                  </td>
                  <td className="p-[10px]  sticky right-0 bg-white">
                    <div className="flex gap-3">
                      <div
                        onClick={() => {
                          setCurrentLeades(item);
                          setIsviewOpen(true);
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <ViewButton />
                      </div>

                      <div
                        onClick={() => {
                          if (action.deletePermit) {
                            setCurrentLeades(item);
                            setIsDeleteOpen(true);
                          } else {
                            toast.error("You don't have permission for delete");
                          }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <DeleteButton />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}
        />
      </div>
      <div>
        <Popup
          title={"Status Change"}
          setIsOpen={setIsStatusOpen}
          isOpen={isStatusOpen}
          closeModal={closeStatusModal}
        >
          <LeadDropDown
            getData={getAllLeadsListData}
            onClose={closeStatusModal}
            currentstatus={currentstatus}
          />
        </Popup>

        <Model
          title={"Lead Details"}
          extraClassName="max-w-[80%]"
          setIsOpen={setIsviewOpen}
          isOpen={isviewOpen}
          closeModal={closeViewIncommingModal}
        >
          <ViewallLeads
            onClose={closeViewIncommingModal}
            currentLeades={currentLeades}
          />
        </Model>
      </div>

      {/* <div>
        <Popup  
       title={"Create InComming Appointment"}
       setIsOpen={setIsIncommingOpen}
       isOpen={isIncommingOpen}
       closeModal={closeIncommingModal}
      >
                 <Addincomingform onClose={closeIncommingModal} getAllLeadsListData={getAllLeadsListData} />
                </Popup>
        </div> */}

      <div>
        <Popup
          title={"Create Incoming Appointment"}
          setIsOpen={setIsIncommingOpen1}
          isOpen={isIncommingOpen1}
          closeModal={closeIncommingModal1}
        >
          <Addincomingform onClose={closeIncommingModal1} />
        </Popup>
      </div>

      <div>
        <Popup
          title={"Add Lead"}
          setIsOpen={setAddlead}
          isOpen={Addlead}
          closeModal={closeAddlead}
        >
          <div className="w-full h-full pb-[30px] pt-[20px] px-[30px]">
            <Singleleadform closeAddlead={closeAddlead} getAllLeadsListData={getAllLeadsListData} />
          </div>
        </Popup>
      </div>

      <div>
        <Popup
          title={"Delete Lead"}
          setIsOpen={setIsDeleteOpen}
          isOpen={isDeleteOpen}
          closeModal={closeDeleteModal}
        >
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">
              Do you want to delete the {currentLeades?.leadtype_name} Lead?
            </p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button
                onClick={() => closeDeleteModal()}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={() => {
                  deletepatient();
                }}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default AllLeads;
