import React, { useEffect, useMemo, useState } from "react";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import {
    addleadEndPoint,
  importBranchListEndPoint,
  LeadTypeDataEndPoint,
  sorceTypeListEndPoint,
} from "../../../service/api";
import toast from "react-hot-toast";

function Singleleadform({closeAddlead,getAllLeadsListData}) {
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [leadtypeDropdown, setLeadTypeDropdown] = useState([]);
  const [sroceTypeDropdown, setSroceTypeDropdown] = useState([]);

    const [currentPatient, setCurrentPatient] = useState({});
    const [errors, setErrors] = useState(null);

  useEffect(() => {
    getBranchDropdownData();
    getLeadDropdownData();
    getSroceDropdownData();
  }, []);


  const branchOptions = useMemo(
    () =>
      branchDropdown.map((option) => (
        <option key={option.id} value={option.id}>
          {option.branch_name}
        </option>
      )),
    [branchDropdown]
  );



  const isValidForm = (formDataObject) => {
    const err = {};
    Object.entries(formDataObject).forEach(([field, value]) => {
      if (value.length === 0) {
        err[field] = `${field} field is required`;
      } else {
        err[field] = "";
      }
    });

    // Validate mobile number field
    const mobileRegex = /^[0-9]{10}$/; // Assumes a 10-digit mobile number
    if (!mobileRegex.test(formDataObject.mobile)) {
      err['mobile'] = 'Invalid mobile number';
    } else {
      err['mobile'] = '';
    }

    // Additional validation for mobile number length
    if (formDataObject.mobile.length > 10) {
      err['mobile'] = 'Mobile number should be 10 digits';
    }


    // err['appointment_date'] = '';
    err['additional_mobile'] = '';
    // err['remarks'] = '';
    // err['email'] = '';
    err['camp_name'] = '';

    setErrors((prevState) => ({
      ...prevState,
      ...err,
    }));

    const hasErrors = Object.values(err).some((error) => error.length > 0);

    return !hasErrors;
  };




  const onSubmit = (e) => {
    e.preventDefault();

    const formFields = new FormData(e.target);
    const formDataObject = Object.fromEntries(formFields.entries());

    if (isValidForm(formDataObject)) {

      if (localStorage.getItem("single_form_mode") === "create") {
        // currentPatient['branch'] = document.getElementById('branch').value
        createallIncommingLeads(currentPatient);
        console.log("test",currentPatient)

      }
    } else {
      console.log("Form has validation errors. Please correct them.");
    }
  };


  const createallIncommingLeads = async (body) => {
   
    var response = await postAxiosWithToken({
      url: addleadEndPoint,
      body: body
    })

    if (response != null) {
      toast.success(response.message)
      closeAddlead();
      getAllLeadsListData();
    //   navigate("/leads/confirmed")
    }
  }




  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: importBranchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const getLeadDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadTypeDropdown(response.data.list);
    }
  };
  const getSroceDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeListEndPoint,
    });

    if (response !== null) {
      setSroceTypeDropdown(response.data.list);
    }
  };


  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    // if (name == "source_type") {
    //   const selectedLabel = e.target.options[e.target.selectedIndex].label;
    //   setSelectedOptionLabel(selectedLabel);
    // }
    // if (name == "mobile") {
    //   setMobileNumber(e.target.value);
    // }
    // if (name == "additional_mobile") {
    //   setAlterNumber(e.target.value);
    //   // handleSearch();
    // }

    setCurrentPatient((prevState) => ({
      ...prevState,
      [name]: value,

    }));


    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };
  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="flex gap-[10px] mt-4">
          <div className="w-1/2">
            <p className="mb-[5px] text-[15px]">
              Name <span className="text-red-500">*</span>
            </p>

            <input
              type="text"
              name="name"
              placeholder="Name"
              className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              value={currentPatient.name}
              onChange={onValueUpdate}
            />
            <p style={{ color: 'red' }}>{errors?.name}</p>
          </div>

          <div className="w-1/2">
            <p className="mb-[5px] text-[15px]">Phone Number <span className="text-red-500">*</span></p>
            <input
              type="text"
              name="mobile"
              id="mobile"
              placeholder="Phone Number"
              className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              value={currentPatient.mobile}
              onChange={onValueUpdate}
            />
            <p style={{ color: 'red' }}>{errors?.mobile}</p>
          </div>
        </div>
        <div className="flex gap-[10px] mt-4">
          <div className="w-1/2">
            <p className="mb-[5px] text-[15px]">
              Additional Number 
              {/* <span className="text-red-500">*</span> */}
            </p>

            <input
              type="text"
              name="additional_mobile"
              placeholder="Additional Number"
              className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              value={currentPatient.additional_mobile}
              onChange={onValueUpdate}
            />
            <p style={{ color: 'red' }}>{errors?.additional_mobile}</p>
          </div>
          {/* <div className="w-1/2 ">
            <p className="mb-[5px] text-[15px]">
              Email<span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              value={currentPatient.email}
              onChange={onValueUpdate}
            />
            {   <p style={{ color: 'red' }}>{errors?.email}</p>}///
          </div> */}

<div className="w-1/2">
            <p className="mb-[5px] text-[15px]">Camp Name 
              {/* <span className="text-red-500">*</span> */}
              </p>
            <input
              type="text"
              name="camp_name"
              id="camp_name"
              placeholder="camp_name"
              className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              value={currentPatient.camp_name}
              onChange={onValueUpdate}
            />
            <p style={{ color: 'red' }}>{errors?.camp_name}</p>
          </div>
        </div>
        <div className="flex gap-[10px] mt-4">
          

          <div className="w-1/2">
            <p className="mb-[5px] text-[15px]">Location <span className="text-red-500">*</span></p>
            <input
              type="text"
              name="location"
              id="location"
              placeholder="location"
              className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              value={currentPatient.location}
              onChange={onValueUpdate}
            />
            <p style={{ color: 'red' }}>{errors?.location}</p>
          </div>

          <div className="w-1/2 ">
            <p className="mb-[5px] text-[15px]">
              Branch <span className="text-red-500">*</span>
            </p>
            {localStorage.getItem("branch") == 0 ? (
             <div>
                 <select
                placeholder="Branch"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="branch"
                value={currentPatient.branch}
                onChange={onValueUpdate}
              >
                <option value={"0"}>--Select Branch--</option>
               {branchOptions}
              </select>
              <p style={{ color: 'red' }}>{errors?.branch}</p>
             </div>
            ) : (
              ""
            )}
            
          </div>
        </div>

        <div className="flex gap-[10px] mt-4">
         

          <div className="w-1/2 ">
            <p className="mb-[5px] text-[15px]">
              Source Type <span className="text-red-500">*</span>
            </p>
            <select
              placeholder="Types of Lead"
              className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
              name="source_type"
              id="source_type"
              value={currentPatient.source_type}
                onChange={onValueUpdate}
            >
              <option value={""}>--select Source Type--</option>
              {sroceTypeDropdown.map((option) => (
                <option
                  className="capitalize"
                  key={"source_type" + option.source_name}
                  value={option.id}
                >
                  {option.source_name}
                </option>
              ))}
            </select>
            <p style={{ color: 'red' }}>{errors?.source_type}</p>
          </div>

          <div className="w-1/2 ">
            <p className="mb-[5px] text-[15px]">
              Types of Lead <span className="text-red-500">*</span>
            </p>
            <select
              placeholder="Types of Lead"
              className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
              name="lead_type"
              id="lead_type"
              value={currentPatient.lead_type}
                onChange={onValueUpdate}
            >
              <option value={""}>--select Types of Lead--</option>
              {leadtypeDropdown.map((option) => (
                <option
                  className="capitalize"
                  key={"lead_type" + option.leadtype_name}
                  value={option.id}
                >
                  {option.leadtype_name}
                </option>
              ))}
            </select>
            <p style={{ color: 'red' }}>{errors?.lead_type}</p>
          </div>
        </div>
        {/* <div className="flex gap-[10px] mt-4">
          

          <div className="w-1/2">
            <p className="mb-[5px] text-[15px]">Remarks <span className="text-red-500">*</span></p>
            <input
              type="text"
              name="remarks"
              id="remarks"
              placeholder="remarks"
              className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              value={currentPatient.remarks}
              onChange={onValueUpdate}
            />
            <p style={{ color: 'red' }}>{errors?.remarks}</p>
          </div>
        </div> */}

        <div className="flex justify-end gap-[10px]">
          <div
              onClick={closeAddlead}
            className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
          >
            CLOSE
          </div>

          <button
            type="submit"
            className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
          >
            SAVE
          </button>
        </div>
      </form>
    </div>
  );
}

export default Singleleadform;
