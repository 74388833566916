import React, { useEffect, useState } from "react";
import SwitchButton from "../../../components/Switch";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  cityDropdownEndPoint,
  countryDropdownEndPoint,
  feesTypeListEndPoint,
  genderTypeListEndPoint,
  LeadTypeDataEndPoint,
  patientEndPoint,
  patientMobilenumberEndpoint,
  patientPhonenumberEndpoint,
  patientTypeListEndPoint,
  sorceTypeListEndPoint,
  sorceTypeotherListEndPoint,
  stateDropdownEndPoint,
} from "../../../service/api";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../../service/apiservice";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { phoneRegExp } from "../../../constant";
import { convertToDate } from "../../../utilities/datetime";
import { previousDay } from "date-fns";
import axios from "axios";
import Popup from "../../../components/Popup";
import Addincomingform from "../../leads/incomingappointment/addincomingform";

function Createpatient() {
  const navigate = useNavigate();

  const [countryDropdown, setCountryDropdown] = useState([]);
  const [stateDropdown, setStateDropdown] = useState([]);
  const [cityDropdown, setCityDropdown] = useState([]);
  const [patienttypeDropdown, setPatientTypeDropdown] = useState([]);
  const [sourceTypeDropdown, setSourceTypeDropdown] = useState([]);
  const [sourceTypeOtherDropdown, setSourceTypeotherDropdown] = useState([]);
  const [genterTypeDropdown, setGenterTypeDropdown] = useState([]);
  const [isIncommingOpen, setIsIncommingOpen] = useState(false);

  const [formMode, setFormMode] = useState("create");
  const [currentPatient, setCurrentPatient] = useState({});

  const [selectedState, setSelelectedState] = useState("");
  const [selectedCountry, setSelelectedCountry] = useState("");
  const [selectedCity, setSelelectedCity] = useState("");
  const [selectedpatienttype, setSelelectedPatientType] = useState("");

  const [dateofBirth, setDateofBirth] = useState("");
  const [createDate, setCreateDate] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alterNumber, setAlterNumber] = useState("");

  const [errors, setErrors] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);

  const [hasPatientDetails, setHasPatientDetails] = useState(false);
  const [usedNumber, setUsedNumber] = useState(null);
  const [dataCame, setDataCame] = useState(false);
  // const [model,setModel ] = useState('');
  const [leadtypeDropdown, setLeadTypeDropdown] = useState([]);

  useEffect(() => {
    patientTypeDropdownList();
    countryDropdownList();
    sourceTypeDropdownList();
    genterTypeDropdownList();
    sourceTypeOtherDropdownList();
    getLeadDropdownData();
    // setCurrentPatient((previousstate) => ({
    //   ...previousstate,
    //   create_date: getCurrentDate(),
    // }));

    setFormMode(localStorage.getItem("patient_form_mode"));

    if (localStorage.getItem("patient_form_mode") === "update") {
      getPatientDetails();
    }
  }, []);

  useEffect(() => {
    if (phoneNumber.length !== 10) {
      if (hasPatientDetails == true && usedNumber == "alternate") {
        setDataCame(true);
      } else if (hasPatientDetails == true && usedNumber == "phone") {
        setDataCame(false);
        setHasPatientDetails(false);
        clearData1();
      } else {
        setDataCame(false);
        setHasPatientDetails(false);
      }
    } else {
      if (!hasPatientDetails) {
        handleSearchPhone(phoneNumber, alterNumber);
      }
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (alterNumber.length !== 10) {
      if (hasPatientDetails == true && usedNumber == "phone") {
        setDataCame(true);
      } else if (hasPatientDetails == true && usedNumber == "alternate") {
        setDataCame(false);
        setHasPatientDetails(false);
        clearData1();
      } else {
        setDataCame(false);
        setHasPatientDetails(false);
      }
    } else {
      if (!hasPatientDetails) {
        handleSearchPhone(phoneNumber, alterNumber);
      }
    }
  }, [alterNumber]);

  const stateDropdownList = async (id) => {
    var response = await getAxios({
      url: stateDropdownEndPoint + id + "/",
    });

    if (response !== null) {
      setStateDropdown(response.data.list);
    }
  };

  function closeIncommingModal() {
    setIsIncommingOpen(false);
  }

  const countryDropdownList = async () => {
    var response = await getAxios({
      url: countryDropdownEndPoint,
    });

    if (response !== null) {
      setCountryDropdown(response.data.list);
    }
  };

  const cityDropdownList = async (id) => {
    var response = await getAxios({
      url: cityDropdownEndPoint + id + "/",
    });

    if (response !== null) {
      setCityDropdown(response.data.list);
    }
  };
  const patientTypeDropdownList = async () => {
    var response = await getAxios({
      url: patientTypeListEndPoint,
    });

    if (response !== null) {
      setPatientTypeDropdown(response.data.list);
    }
  };

  const sourceTypeDropdownList = async () => {
    var response = await getAxios({
      url: sorceTypeListEndPoint,
    });

    if (response !== null) {
      setSourceTypeDropdown(response.data.list);
    }
  };

  const sourceTypeOtherDropdownList = async () => {
    var response = await getAxios({
      url: sorceTypeotherListEndPoint,
    });

    if (response !== null) {
      setSourceTypeotherDropdown(response.data.list);
    }
  };

  const genterTypeDropdownList = async () => {
    var response = await getAxios({
      url: genderTypeListEndPoint,
    });

    if (response !== null) {
      setGenterTypeDropdown(response.data.list);
    }
  };

  const getLeadDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadTypeDropdown(response.data.list);
    }
  };

  const getPatientDetails = async () => {
    var id = localStorage.getItem("patient_form_id");

    var response = await getAxios({
      url: patientEndPoint + id + "/",
    });

    if (response !== null) {
      var tempDetails = {
        city_id: response.data?.city_id,
        state_id: response.data?.state_id,
        country_id: response.data?.country_id,
        pincode: response.data?.pincode,
        file_no: response.data?.file_no,
        name: response.data?.name,
        spouse_name: response.data?.spouse_name,
        dob: response.data?.dob,
        aadhar_no: response.data?.aadhar_no,
        create_date: response.data?.create_date,
        phone: response.data?.phone,
        alternate_no: response.data?.alternate_no,
        email: response.data?.email,
        address: response.data?.address,
        landmark: response.data?.landmark,
        remarks: response.data?.remarks,
        google_review: response.data?.google_review,
        source_types: response.data?.source_types,
        gender: response.data?.gender,
        patienttype: response.data?.patienttype,
        lead_type: response.data?.lead_type,
      };

      stateDropdownList(response.data.country_id);
      cityDropdownList(response.data.state_id);
      setSelelectedState(response.data.state_id);
      setSelelectedCountry(response.data.country_id);
      setSelelectedCity(response.data.city_id);
      setSelectedGender(response.data.gender);

      setSelelectedPatientType(response.data.patienttype);

      setCurrentPatient(tempDetails);
    }
  };

  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    setCurrentPatient((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name == "phone") {
      setPhoneNumber(e.target.value);
    }
    if (name == "alternate_no") {
      setAlterNumber(e.target.value);
    }

    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };

  const isValidForm = (formDataObject) => {
    const err = {};
    Object.entries(formDataObject).forEach(([field, value]) => {
      if (value.length === 0) {
        err[field] = `${field} field is required`;
      } else {
        err[field] = "";
      }
    });

    // Validate mobile number field
    const mobileRegex = /^[0-9]{10}$/; // Assumes a 10-digit mobile number
    if (!mobileRegex.test(formDataObject.phone)) {
      err["phone"] = "Invalid mobile number";
    } else {
      err["phone"] = "";
    }

    // Validate gender field
    if (!selectedGender) {
      err["gender"] = "Gender selection is required";
    } else {
      err["gender"] = "";
    }

    // Validate alternate mobile number field if provided
    err["aadhar_no"] = "";
    err["alternate_no"] = "";
    err["remarks"] = "";
    err["google_review"] = "";
    err["landmark"] = "";
    err["email"] = "";
    err["file_no"] = "";
    err["dob"] = "";

    setErrors((prevState) => ({
      ...prevState,
      ...err,
    }));

    const hasErrors = Object.values(err).some((error) => error.length > 0);

    return !hasErrors;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formFields = new FormData(e.target);
    const formDataObject = Object.fromEntries(formFields.entries());

    if (isValidForm(formDataObject)) {
      if (localStorage.getItem("patient_form_mode") === "create") {
        formDataObject["gender"] = selectedGender;
        formDataObject["patienttype"] = selectedpatienttype;
        formDataObject["create_date"] = formDataObject.create_date;

        createpatient(formDataObject);
      } else if (localStorage.getItem("patient_form_mode") === "update") {
        formDataObject["gender"] = selectedGender;
        formDataObject["patienttype"] = selectedpatienttype;

        updatePatient(formDataObject);
      }
    } else {
      console.log("Form has validation errors. Please correct them.");
    }
  };

  const createpatient = async (body) => {
    var response = await postAxiosWithToken({
      url: patientEndPoint,
      body: body,
    });

    if (response != null) {
      clearData();
      toast.success(response.message);
      navigate("/patient/all-patient");
    }
  };

  const updatePatient = async (body) => {
    var response = await putAxiosWithToken({
      url: patientEndPoint + localStorage.getItem("patient_form_id") + "/",
      body: body,
    });

    if (response != null) {
      clearData();
      localStorage.removeItem("patient_form_id");
      localStorage.setItem("patient_form_mode", "create");
      toast.success(response.message);
      navigate("/patient/all-patient");
    }
  };

  const clearData = () => {
    setCurrentPatient({
      city_id: "",
      state_id: "",
      country_id: "",
      pincode: "",
      file_no: "",
      name: "",
      spouse_name: "",
      dob: "",
      aadhar_no: "",
      create_date: "",
      gender: "",
      phone: "",
      alternate_no: "",
      email: "",
      address: "",
      landmark: "",
      remarks: "",
      google_review: "",
      source_types: "",
      patienttype: "",
      lead_type: "",
    });
  };

  const isObjectEmpty = (obj) => {
    localStorage.setItem("incomming_form_mode", "create");
    return Object.values(obj).every((value) => value === "");
  };

  const clearData1 = () => {
    setCurrentPatient({
      source_types: "",
      lead_type: "",
    });
  };

  const mobileNumbers = {
    mobile: mobileNumber,
  };
  const PhoneNumbers = {
    phone: phoneNumber,
    alternate_no: alterNumber,
  };

  // const handleSearch = async () => {
  //   var response = await postAxiosWithToken({
  //     url: patientMobilenumberEndpoint,
  //     body: mobileNumbers,
  //   });

  //   if (response != null) {
  //     toast.success(response.message);
  //     // setHasPatientDetails(true);

  //     setDataCame(true);
  //     var tempDetails = {
  //       name: response.data?.name,
  //       created_at: response.data?.created_at,
  //       phone: response.data?.mobile,
  //       landmark: response.data?.location,
  //       email: response.data?.email,
  //       source_types: response.data?.source_type,
  //       remarks: response.data?.remarks,
  //       lead_type: response.data?.lead_type,
  //     };
  //     setCurrentPatient(tempDetails);
  //   }else{
  //     setDataCame(false)
  //     // setModel("testing");
  //     // setHasPatientDetails(false);
  //     clearData()
  //   }
  // };

  const handleSearch = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("login"))?.token;

      // Correct the axios.post method
      const response = await axios.post(
        patientMobilenumberEndpoint,
        { mobile: mobileNumbers.mobile }, // Use an object for the request body
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json", // Ensure content type is set correctly
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === 201 || response.data.status === 200) {
          if (response != null) {
            toast.success(response.data.message);
            // setHasPatientDetails(true);

            setDataCame(true);
            var tempDetails = {
              name: response.data?.data?.name,
              created_at: response.data?.data?.created_at,
              phone: response.data?.data?.mobile,
              alternate_no: response.data?.data?.additional_mobile,
              spouse_name: response.data?.data?.spouse_name,
              landmark: response.data?.data?.location,
              email: response.data.data?.email,
              source_types: response.data?.data.source_type,
              remarks: response.data?.data.remarks,
              lead_type: response.data?.data.lead_type,
            };
            setCurrentPatient(tempDetails);
          }
        } else if (response.data.status === 400) {
          toast.error(
            `${Object.entries(response.data.data)[0][0]}, ${
              Object.entries(response.data.data)[0][1]
            }`
          );
          return null;
        } else if (response.data.status === 401) {
          toast.error(response.data.message);
          return null;
        } else if (response.data.status === 204) {
          setIsIncommingOpen(true);
          setDataCame(false);
          clearData();
        } else if (response.data.status === 404) {
          toast.error(response.data.message);
          return null;
        } else if (response.data.status === 406) {
          toast.error(response.data.message);
          return null;
        } else {
          toast.error(response.message);
          return null;
        }
      } else {
        toast.info("No data found for the given mobile number.");
      }
    } catch (error) {
      console.error("Error:", error);

      if (error.response) {
        toast.error(`Error: ${error.response.data?.message || error.message}`);
      } else {
        toast.error("Network error, please try again.");
      }
    }
  };

  const handleSearchPhone = async (phone1, phone2) => {
    let phone_no = PhoneNumbers?.phone?.length || 0;
    let bodyData = {
      phone: phone1?.length > 0 ? phone1 : "",
      alternate_no: phone2?.length > 0 ? phone2 : "",
    };
    if (phone_no === 10) {
      var response = await postAxiosWithToken({
        url: patientPhonenumberEndpoint,
        body: bodyData,
        showToast: false,
      });

      if (response != null) {
        toast.success(response.message);

        setHasPatientDetails(true);
        setUsedNumber(response.is_phone ? "phone" : "alternate");
        setDataCame(true);

        var tempDetails = {
          source_types: response.data?.source_type,
          lead_type: response.data?.lead_type,
        };
        setCurrentPatient(tempDetails);
      } else {
        setHasPatientDetails(false);
        setDataCame(false);
        clearData1();
      }
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are zero-indexed
    let day = today.getDate();

    // Add leading zero if the month or day is a single digit
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    // Format: YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <div className="border p-[15px] rounded-xl shadow-lg flex items-center justify-evenly gap-[40px]">
        <div className=" w-4/5">
          <p className="mb-[5px] text-[15px]"> Search Mobile Number </p>
          <input
            type="text"
            placeholder="search"
            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
            name="first_name"
            id="forminit"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>
        <div className="w-1/5 flex gap-[20px] mt-4">
          <button
            onClick={handleSearch}
            className="bg-[#0176FF] border border-gray-300 rounded-lg text-white p-[10px]"
          >
            Search
          </button>
        </div>
      </div>

      {isObjectEmpty(currentPatient) ? (
        ""
      ) : (
        // <h2 className="flex justify-center mt-[30px]">Create the appointment now, please</h2>
        <form onSubmit={onSubmit}>
          <p className=" mt-[30px] sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
            Patient Details
          </p>
          <div className="border p-[15px] rounded-xl shadow-lg mt-[40px]">
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">File no</p>
                <input
                  type="text"
                  placeholder="File no"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="file_no"
                  onChange={onValueUpdate}
                  value={currentPatient.file_no}
                />
                {/* <p style={{ color: "red" }}>{errors?.file_no}</p> */}
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Patient Name <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Name"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="name"
                  onChange={onValueUpdate}
                  value={currentPatient.name}
                />
                <p style={{ color: "red" }}>{errors?.name}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Father/Spouse Name <span className="text-red-500">*</span>
                </p>
                <input
                  placeholder="Spouse Name"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="spouse_name"
                  onChange={onValueUpdate}
                  value={currentPatient.spouse_name}
                />
                <p style={{ color: "red" }}>{errors?.spouse_name}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">Date Of Birth</p>
                <input
                  type="date"
                  placeholder="menu group"
                  class="sm:mb-[5px] h-[45px]  border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  id="dob"
                  name="dob"
                  value={currentPatient.dob}
                  onChange={(e) => {
                    setDateofBirth(e.target.value);
                    onValueUpdate(e);
                  }}
                />
                <p style={{ color: "red" }}>{errors?.dob}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">Aadhar Number</p>
                <input
                  type="text"
                  placeholder="aadhar_no"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="aadhar_no"
                  onChange={onValueUpdate}
                  value={currentPatient.aadhar_no}
                />
                {/* <p style={{ color: 'red' }}>{errors?.aadhar_no}</p> */}
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Create Date <span className="text-red-500">*</span>
                </p>
                <input
                  type="date"
                  placeholder="menu group"
                  class="sm:mb-[5px] h-[45px]  border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="create_date"
                  value={currentPatient.create_date}
                  onChange={onValueUpdate}
                />
                <p style={{ color: "red" }}>{errors?.create_date}</p>
              </div>

              <div className="sm:my-[5px] ">
                <p className="mb-[5px] text-[15px]">
                  Patient Type <span className="text-red-500">*</span>
                </p>
                <select
                  placeholder="Patient Type"
                  className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                  name="patienttype"
                  value={selectedpatienttype}
                  onChange={(e) => {
                    setSelelectedPatientType(e.target.value);
                    onValueUpdate(e);
                  }}
                >
                  <option value={""}>--select patient type--</option>
                  {patienttypeDropdown.map((option) => (
                    <option
                      className="capitalize"
                      key={"country" + option.typename}
                      value={option.id}
                    >
                      {option.typename}
                    </option>
                  ))}
                </select>

                <p style={{ color: "red" }}>{errors?.patienttype}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Gender<span className="text-red-500">*</span>
                </p>
                <div className="flex gap-[30px]">
                  {genterTypeDropdown?.map((item, index) => (
                    <div className="flex gap-2" key={index}>
                      <input
                        type="checkbox"
                        checked={selectedGender === item.id}
                        onChange={() => {
                          if (selectedGender === item.id) {
                            setSelectedGender(null); // Deselect if already selected
                          } else {
                            setSelectedGender(item.id); // Select if not selected
                          }
                        }}
                      />
                      <label>{item.gender_name}</label>
                    </div>
                  ))}
                </div>
                <p style={{ color: "red" }}>{errors?.gender}</p>
              </div>
            </div>
          </div>

          <p className=" mt-[30px] sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
            Contact Details
          </p>
          <div className="border p-[15px] rounded-xl shadow-lg mt-[40px]">
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Mobile Number <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Mobile Number "
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="phone"
                  value={currentPatient?.phone}
                  onChange={onValueUpdate}
                />
                <p style={{ color: "red" }}>{errors?.phone}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">Alternate Mobile Number</p>
                <input
                  type="text"
                  placeholder="Alternate Mobile Number"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="alternate_no"
                  value={currentPatient?.alternate_no}
                  onChange={onValueUpdate}
                />
                {/* <p style={{ color: 'red' }}>{errors?.alternate_no}</p> */}
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">E-Mail</p>
                <input
                  type="text"
                  placeholder="E-Mail"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="email"
                  value={currentPatient.email}
                  onChange={onValueUpdate}
                />
                {/* <p style={{ color: 'red' }}>{errors?.email}</p> */}
              </div>
            </div>
          </div>

          <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5 ">
            <p className=" mt-[30px] sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
              Address Details
            </p>
          </div>
          <div className="border p-[15px] rounded-xl shadow-lg mt-[40px]">
            <div className="grid xl:grid-cols-2 sm:grid-cols-2 gap-5">
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Street Address <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Street Address"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="address"
                  value={currentPatient?.address}
                  onChange={onValueUpdate}
                />
                <p style={{ color: "red" }}>{errors?.address}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Apt/ Land Mark/ Others (optional)
                </p>
                <input
                  type="text"
                  placeholder="Apt/ Land Mark/ Others"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="landmark"
                  onChange={onValueUpdate}
                  value={currentPatient?.landmark}
                />
                {/* <p style={{ color: 'red' }}>{errors?.landmark}</p> */}
              </div>
            </div>

            <div className="grid xl:grid-cols-4 sm:grid-cols-2 gap-5 mt-4">
              <div className="sm:my-[5px] ">
                <p className="mb-[5px] text-[15px]">
                  Country <span className="text-red-500">*</span>
                </p>
                <select
                  placeholder="country"
                  className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                  name="country_id"
                  value={selectedCountry}
                  onChange={(e) => {
                    setSelelectedCountry(JSON.parse(e.target.value));
                    stateDropdownList(e.target.value);
                    onValueUpdate(e);
                  }}
                >
                  <option value={""}>--select country--</option>
                  {countryDropdown.map((option) => (
                    <option
                      className="capitalize"
                      key={"country" + option.country_name}
                      value={option.id}
                    >
                      {option.country_name}
                    </option>
                  ))}
                </select>

                <p style={{ color: "red" }}>{errors?.country_id}</p>
              </div>
              <div className="sm:my-[5px] ">
                <p className="mb-[5px] text-[15px]">
                  State <span className="text-red-500">*</span>
                </p>
                <select
                  placeholder="state"
                  className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg  w-full p-[11px] text-[17px] outline-none"
                  name="state_id"
                  value={selectedState}
                  onChange={(e) => {
                    setSelelectedState(JSON.parse(e.target.value));
                    cityDropdownList(e.target.value);
                    onValueUpdate(e);
                  }}
                >
                  <option value={""}>--select state--</option>
                  {stateDropdown.map((option) => (
                    <option
                      key={option.state_name}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.state_name}
                    </option>
                  ))}
                </select>

                <p style={{ color: "red" }}>{errors?.state_id}</p>
              </div>

              <div className="sm:my-[5px] ">
                <p className="mb-[5px] text-[15px]">
                  City <span className="text-red-500">*</span>
                </p>
                <select
                  placeholder="city"
                  className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg  w-full p-[11px] text-[17px] outline-none"
                  name="city_id"
                  value={selectedCity}
                  onChange={(e) => {
                    setSelelectedCity(e.target.value);
                    onValueUpdate(e);
                  }}
                >
                  <option value={""}>--select city--</option>
                  {cityDropdown.map((option) => (
                    <option
                      key={option.city_name}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.city_name}
                    </option>
                  ))}
                </select>

                <p style={{ color: "red" }}>{errors?.city_id}</p>
              </div>

              <div className="sm:my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Pincode <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="pincode"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg  w-full p-[10px] text-[17px] outline-none"
                  name="pincode"
                  onChange={onValueUpdate}
                  value={currentPatient?.pincode}
                />
                <p style={{ color: "red" }}>{errors?.pincode}</p>
              </div>
            </div>
          </div>

          <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
            <p className=" mt-[30px] sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
              Referral Details
            </p>
          </div>
          <div className="border p-[15px] rounded-xl shadow-lg mt-[40px]">
            <div className="grid xl:grid-cols-2 sm:grid-cols-2 gap-5">
              {localStorage.getItem("patient_form_mode") === "update" ? (
                <div className="w-1/2">
                  <p className="mb-[5px] text-[15px]">
                    Source Type <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="Source Type"
                    id="source_types"
                    className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                    name="source_types"
                    // onClick={(e)=> setcampName(e.target.value)}
                    value={currentPatient.source_types}
                    onChange={onValueUpdate}
                    readonly
                  >
                    <option value={""}>--select source--</option>
                    {sourceTypeDropdown.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        className="capitalize"
                      >
                        {option.source_name}
                      </option>
                    ))}
                  </select>
                  <p style={{ color: "red" }}>{errors?.source_types}</p>
                </div>
              ) : (
                <div className="sm:my-[5px] ">
                  <p className="mb-[5px] text-[15px]">
                    Referral Type <span className="text-red-500">*</span>
                  </p>
                  <select
                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg  w-full p-[11px] text-[17px] outline-none"
                    name="source_types"
                    onChange={onValueUpdate}
                    value={currentPatient?.source_types}
                    disabled={dataCame}
                  >
                    <option value={""}>--select Referral Type--</option>
                    {sourceTypeDropdown.map((option) => (
                      <option
                        key={option.source_name}
                        value={option.id}
                        className="capitalize"
                      >
                        {option.source_name}
                      </option>
                    ))}
                  </select>
                  <p style={{ color: "red" }}>{errors?.source_types}</p>
                </div>
              )}

              {localStorage.getItem("patient_form_mode") === "update" ? (
                <div className="w-1/2">
                  <p className="mb-[5px] text-[15px]">
                    Lead Type <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="Source Type"
                    id="lead_type"
                    className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                    name="lead_type"
                    // onClick={(e)=> setcampName(e.target.value)}
                    value={currentPatient.lead_type}
                    onChange={onValueUpdate}
                    readonly
                  >
                    <option value={""}>--select lead Type--</option>
                    {leadtypeDropdown.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        className="capitalize"
                      >
                        {option.leadtype_name}
                      </option>
                    ))}
                  </select>
                  <p style={{ color: "red" }}>{errors?.lead_type}</p>
                </div>
              ) : (
                <div className="sm:my-[5px] ">
                  <p className="mb-[5px] text-[15px]">
                    Lead Type <span className="text-red-500">*</span>
                  </p>
                  <select
                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg  w-full p-[11px] text-[17px] outline-none"
                    name="lead_type"
                    onChange={onValueUpdate}
                    value={currentPatient?.lead_type}
                    disabled={dataCame}
                  >
                    <option value={""}>--select lead Type--</option>
                    {leadtypeDropdown.map((option) => (
                      <option
                        key={option.lead_name}
                        value={option.id}
                        className="capitalize"
                      >
                        {option.leadtype_name}
                      </option>
                    ))}
                  </select>
                  <p style={{ color: "red" }}>{errors?.lead_type}</p>
                </div>
              )}
            </div>

            <div className="grid xl:grid-cols-2 sm:grid-cols-2 gap-5">
              <div className="sm:my-[5px] ">
                <p className="mb-[5px] text-[15px]">Remarks</p>
                <textarea
                  className="border-2 border-black-500 rounded-[4px] w-full"
                  name="remarks"
                  onChange={onValueUpdate}
                  id=""
                  cols="50"
                  rows="3"
                  value={currentPatient?.remarks}
                ></textarea>
                {/* <p style={{ color: 'red' }}>{errors?.remarks}</p> */}
              </div>

              <div className="sm:my-[5px] ">
                <p className="mb-[5px] text-[15px]">Google Review</p>
                <textarea
                  className="border-2 border-black-500 rounded-[4px] w-full"
                  name="google_review"
                  value={currentPatient?.google_review}
                  onChange={onValueUpdate}
                  id=""
                  cols="50"
                  rows="3"
                ></textarea>
                {/* <p style={{ color: 'red' }}>{errors?.google_review}</p> */}
              </div>
            </div>
          </div>

          <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 pt-5 pb-5">
            <button
              id="backnav"
              onClick={(e) => {
                e.preventDefault();
                setFormMode("create");
                navigate(-1);
              }}
              className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
            >
              close<span className="lowercase">(esc)</span>
            </button>
            <button
              type="submit"
              id="formsubmit"
              className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              save<span className="lowercase">(ctrl+s)</span>
            </button>
          </div>
        </form>
      )}

      <div>
        <Popup
          title={"Create a New Appointment"}
          setIsOpen={setIsIncommingOpen}
          isOpen={isIncommingOpen}
          closeModal={closeIncommingModal}
        >
          <Addincomingform
            onClose={closeIncommingModal}
            // getAllLeadsListData={getAllLeadsListData}
          />
        </Popup>
      </div>
    </div>
  );
}

export default Createpatient;
